import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './scss/style.scss';

localStorage.removeItem('auth');
localStorage.removeItem('apps');

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const CheckInvoices = React.lazy(() => import('./views/pages/checkinvoices/CheckInvoices'));
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/checkout" name="CheckOut" render={props => <CheckInvoices {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/403" name="Page 403" render={props => <Page403 {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
} 

export default App;
